import { defineStore } from 'pinia'
import { FirebaseMessaging } from '@capacitor-firebase/messaging'
import { useLocalStorage } from '@vueuse/core'

export const useNotificationsStore = defineStore('notifications', () => {
  FirebaseMessaging.addListener('notificationReceived', ({ notification }) => {
    console.log('Received a notification', notification)
    alert(notification.title + '\n\n' + notification.body)
  })

  FirebaseMessaging.addListener('tokenReceived', (token) => {
    console.log('Received a token', token)
  })

  function isSupported () {
    return false //Capacitor.isNativePlatform() Turn off until this version will be in the App Store.
  }

  async function registerNotifications (topic: string) {
    let permStatus = await FirebaseMessaging.checkPermissions()

    if (permStatus.receive === 'prompt') {
      permStatus = await FirebaseMessaging.requestPermissions()
    }

    if (permStatus.receive !== 'granted') {
      alert('Notifikace nejsou povoleny. Můžeš je zapnout v nastavení systému.')
      return
    }

    await FirebaseMessaging.subscribeToTopic({ topic })

    isTopicSubscribed(topic).value = true
  }

  function isTopicSubscribed (topic: string) {
    return useLocalStorage('notifications-subscribed:' + topic, false)
  }

  return {
    isTopicSubscribed,
    isSupported,
    registerNotifications,
  }
})
