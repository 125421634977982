import { initializeApp } from 'firebase/app'
import { initializeFirestore, persistentLocalCache, persistentMultipleTabManager } from 'firebase/firestore'


const firebaseConfig = {
  apiKey: 'AIzaSyCWWAy_KJyDEme-Du3yK9gOFwN-Tn7OE_Y',
  authDomain: 'vlakfest-2502b.firebaseapp.com',
  projectId: 'vlakfest-2502b',
  storageBucket: 'vlakfest-2502b.appspot.com',
  messagingSenderId: '342137548325',
  appId: '1:342137548325:web:3214083c29060374afd9cc',
  measurementId: 'G-P49GYQR2B5',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export default app

initializeFirestore(app, {
  localCache: persistentLocalCache({ tabManager: persistentMultipleTabManager() })
})
