<template>
  <ion-accordion>
    <ion-item slot="header">
      <div v-if="isCurrent" id="ongoing-cursor" slot="start" />
      <div class="time">
        <s v-if="item.time.originalStart">
          {{ format(item.time.originalStart, 'HH:mm') }}
        </s>
        {{ format(item.time.start, 'HH:mm') }}
      </div>
      <ion-label>
        {{ item.title }}
      </ion-label>
    </ion-item>

    <div slot="content" class="ion-padding item-lines-full">
      <div class="item-actions">
        <ion-button v-if="useEventStore().currentMember?.canWrite" fill="clear" size="small" @click="editItem">
          <ion-icon slot="icon-only" :icon="createOutline" />
        </ion-button>
      </div>
      <TextFormatter :value="item.description" />
      <ContentBlocks :value="item.blocks" />
    </div>
  </ion-accordion>
</template>

<script lang="ts" setup>
import { IonAccordion, IonButton, IonIcon, IonItem, IonLabel, modalController } from '@ionic/vue'
import { createOutline } from 'ionicons/icons'
import ContentBlocks from '@/components/ContentBlocks.vue'
import TextFormatter from '@/components/TextFormatter.vue'

import { EventScheduleItem, useEventStore } from '@/store/event'

import { format } from '@/lib/date-fns-local'

const props = defineProps<{
  item: EventScheduleItem
  isCurrent: boolean
}>()

async function editItem () {
  const ScheduleItemForm = await import('@/components/ScheduleItemForm.vue')
  return (await modalController.create({
    component: ScheduleItemForm.default,
    componentProps: { origItem: props.item },
    ...ScheduleItemForm.modalOptions
  })).present()
}

</script>

<style scoped>
.time {
  width: 55px;
}
.time s {
  display: block;
  font-size: 0.7em;
  color: var(--ion-color-danger-shade);
}

:deep(.ion-accordion-toggle-icon) {
  font-size: 0.8em;
  margin-left: 8px;
}
.accordion-expanding .item ion-label,
.accordion-expanded .item ion-label {
  white-space: normal;
}

.item-actions {
  float: right;
  font-size: 24px;
}
.item-actions ion-button {
  --padding-end: 0.45em;
  --padding-start: 0.45em;
}

#ongoing-cursor {
  position: absolute;
  left: 0;
  width: 5px;
  height: 100%;
  z-index: 10;
  background-color: #C00;
}
</style>
