import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import firebaseApp from './firebase'

import { IonicVue } from '@ionic/vue'
import { createPinia } from 'pinia'
import { Capacitor } from '@capacitor/core'
import { CapacitorUpdater } from '@capgo/capacitor-updater'
import type { BundleInfo } from '@capgo/capacitor-updater'
import { App as CapApp, URLOpenListenerEvent } from '@capacitor/app'
import { SplashScreen } from '@capacitor/splash-screen'
import { VueFire, VueFireAuthWithDependencies } from 'vuefire'
import { indexedDBLocalPersistence } from 'firebase/auth'


/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme variables */
import './theme/variables.css'


const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(createPinia())
  .use(VueFire, {
    firebaseApp,
    modules: [
      VueFireAuthWithDependencies({
        dependencies: {
          persistence: indexedDBLocalPersistence,
        },
      })
    ],
  })

router.isReady().then(() => {
  app.mount('#app')
})


/**
 * Provide base URL
 */
let baseUrl = 'https://webapp.vlakfest.cz'
if (Capacitor.isNativePlatform())
  baseUrl = 'https://app.vlakfest.cz'
else if (process.env.NODE_ENV === 'development')
  baseUrl = 'http://localhost:8080'

app.provide('baseUrl', baseUrl)


/**
 * Update web part in native app
 */
CapacitorUpdater.notifyAppReady()
let versionDownloaded: BundleInfo | undefined
const updateBaseUrl = 'https://webapp.vlakfest.cz'

CapApp.addListener('appStateChange', async ({ isActive }) => {
  if (isActive) {
    // Check for updates
    const response = await fetch(updateBaseUrl + '/update.json')
    if (!response.ok) return
    const availableVersion = (await response.json()).build_datetime
    console.log('available version', availableVersion)

    if (process.env.VUE_APP_BUILD_DATETIME && availableVersion > process.env.VUE_APP_BUILD_DATETIME) {
      console.log('downloading update')
      versionDownloaded = await CapacitorUpdater.download({
        version: availableVersion,
        url: updateBaseUrl + '/update.zip',
      })
    }
  }
  if (!isActive && versionDownloaded && versionDownloaded.version !== '') {
    // Do the switch when user leave app
    SplashScreen.show()
    try {
      await CapacitorUpdater.set(versionDownloaded)
    } catch (err) {
      console.log(err)
      SplashScreen.hide() // in case the set fail, otherwise the new app will have to hide it
    }
  }
})


/**
 * Handle deep links
 */
CapApp.addListener('appUrlOpen', function (event: URLOpenListenerEvent) {
  const slug = event.url.split(baseUrl!).pop()

  // We only push to the route if there is a slug present
  if (slug) {
    router.push(slug)
  }
})
