<template>
  <template v-for="(item, index) in props.value">
    <a :key="index" v-if="item.type === 'link'" :href="item.url">{{ item.label }}</a>
  </template>
</template>

<script lang="ts" setup>
import type { ContentBlock } from '@/store/event'

const props = defineProps<{
  value: ContentBlock[]
}>()
</script>

<style scoped>
a {
  display: inline-block;
  padding: 0 8px 8px 0;
  font-size: smaller;
  font-weight: 600;
}
</style>
