<template>
  <ion-page>
    <ion-header collapse="fade" translucent>
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button id="trigger-user-menu">&nbsp;</ion-button>
        </ion-buttons>
        <ion-title>Vyber akci</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-popover trigger="trigger-user-menu" trigger-action="click" dismiss-on-select>
      <ion-list lines="full" class="ion-no-padding">
        <ion-item v-if="$auth.currentUser" @click="$auth.logout()" :detail="false">
          Odhlásit se
        </ion-item>
        <ion-item v-else router-link="/auth" :detail="false">
          Přihlásit se
        </ion-item>
      </ion-list>
    </ion-popover>

    <ion-content fullscreen>
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Vyber akci</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-card
        v-for="event in events" :key="event.id"
        class="event-banner"
        :style="{ backgroundImage: `url(${event.bannerUrl})` }"
        button
        @click="openEvent(event.id)"
      >
        {{ event.displayName }}
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script lang="ts" setup>
import { IonButton, IonButtons, IonCard, IonHeader, IonItem, IonList, IonPage, IonPopover, IonToolbar, IonTitle, IonContent } from '@ionic/vue'

import type { EventType } from '@/store/event'
import { eventId } from '@/store/event'
import { collection } from 'firebase/firestore'
import { useFirestore, useCollection } from 'vuefire'
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/store/auth'

const $auth = useAuthStore()
const $router = useRouter()

const events = useCollection<EventType>(collection(useFirestore(), 'events'))


function openEvent(id: string) {
  eventId.value = id
  $router.push('/event')
}
</script>

<style scoped>
.event-banner {
  aspect-ratio: 8 / 5;
  display: flex;
  align-items: flex-end;
  padding: 6px 12px;
  font-size: 34px;
  font-weight: 700;
  background-size: cover;
  background-position: center;
	color: #fff;
	letter-spacing: normal;
}

.event-banner::part(native) {
  background: transparent;
  text-shadow: 1px 1px 6px #0008;
}
</style>
