<template>
  <ion-card v-if="$store.isSupported() && !isTopicSubscribed">
    <ion-card-header>
      <ion-card-title>Zapni si notifikace</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <p>Pokud bude nějaká důležitá změna v programu nebo nějaká jiná důležitá zpráva, tak ti můžeme poslat notifikaci.</p>
    </ion-card-content>
    <ion-button fill="clear" @click="$store.registerNotifications(topic)">Zapnout</ion-button>
  </ion-card>
</template>

<script setup lang="ts">
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/vue'

import { useNotificationsStore } from '@/store/notifications'

const $store = useNotificationsStore()

const props = defineProps<{
  eventId: string
}>()
const topic = `event.${props.eventId}.important`
const isTopicSubscribed = $store.isTopicSubscribed(topic)
</script>