import {
  collection,
  deleteDoc,
  doc,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  setDoc,
  SnapshotOptions,
} from 'firebase/firestore'
import {
  firestoreDefaultConverter,
  useFirestore,
  useCollection,
  useDocument,
} from 'vuefire'
import { defineStore } from 'pinia'
import { SplashScreen } from '@capacitor/splash-screen'
import { computed, watch } from 'vue'
import { useLocalStorage } from '@vueuse/core'
import { useRouter } from 'vue-router'
import { useAuthStore } from './auth'


export class ContentBlock {
  type = 'link'
  label = ''
  url = ''
}

export class EventType {
  displayName = ''
  bannerUrl = ''
  infoTab? = {
    content: '',
  }
}

export class EventScheduleItem {
  readonly id: string
  title = ''
  description = ''
  blocks: ContentBlock[] = []
  time: {
    start: Date
    originalStart?: Date
    duration?: number
  } = { start: new Date() }

  constructor (id: string, start?: Date) {
    this.id = id
    if (start) this.time.start = start
  }
}

const EventScheduleConverter: FirestoreDataConverter<EventScheduleItem> = {
  toFirestore(item: EventScheduleItem): any {
    const { id: _, ...data } = firestoreDefaultConverter.toFirestore(item)
    data.blocks = item.blocks.map(block => ({ ...block }))
    if (item.time.duration) data.time.duration = item.time.duration
    return data
  },

  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): EventScheduleItem {
    const data = firestoreDefaultConverter.fromFirestore(snapshot, options)
    if (!data) throw new Error('No data in snapshot to convert')
    data.time.start = data.time.start.toDate()
    if (data.time.originalStart) data.time.originalStart = data.time.originalStart.toDate()
    return Object.assign(new EventScheduleItem(data.id), data)
  }
}

export class EventPerformer {
  readonly id: string
  photoUrl = ''
  name = ''
  description = ''
  blocks: ContentBlock[] = []
  sort = 0

  constructor (id: string) {
    this.id = id
  }
}

const EventPerformersConverter: FirestoreDataConverter<EventPerformer> = {
  toFirestore(item: EventPerformer): any {
    const { id: _, ...data } = firestoreDefaultConverter.toFirestore(item)
    data.blocks = item.blocks.map(block => ({ ...block }))
    return data
  },

  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): EventPerformer {
    const data = firestoreDefaultConverter.fromFirestore(snapshot, options)
    if (!data) throw new Error('No data in snapshot to convert')
    return Object.assign(new EventPerformer(data.id), data)
  }
}

export class EventMember {
  canWrite = false
  canReadMembers = false
}

export const eventId = useLocalStorage<string>('currentEventId', '')


export const useEventStore = defineStore('event', () => {
  const db = useFirestore()
  const $router = useRouter()
  const $auth = useAuthStore()

  if (!eventId.value) throw new Error('No event selected')
  const eventRef = computed(() => doc(db, 'events', eventId.value))
  const event = useDocument<EventType>(eventRef)

  const currentMember = useDocument<EventMember>(computed(
    () => $auth.currentUser && doc(eventRef.value, 'members', $auth.currentUser.uid))
  )

  const scheduleRef = computed(() => collection(eventRef.value, 'schedule').withConverter(EventScheduleConverter))
  const schedule = useCollection<EventScheduleItem>(scheduleRef)

  const performersRef = computed(() => collection(eventRef.value, 'performers').withConverter(EventPerformersConverter))
  const performers = useCollection<EventPerformer>(performersRef)

  const membersRef = computed(
    () => currentMember.value?.canReadMembers ? collection(eventRef.value, 'members') : null
  )
  const members = useCollection<EventMember>(membersRef)

  // Show and hide splash screen when loading data
  watch([event.pending, schedule.pending], () => {
    if (event.pending.value || schedule.pending.value)
      SplashScreen.show({ autoHide: false })
    else {
      SplashScreen.hide()
      if (!event.value) { // No event found
        $router.push('/events')
      }
    }
  }, { immediate: true })

  // Docs setters
  async function setEvent(event: EventType) {
    await setDoc(eventRef.value, event)
  }
  async function setScheduleItem (item: EventScheduleItem) {
    await setDoc(doc(scheduleRef.value, item.id), item)
  }
  async function removeScheduleItem (id: string) {
    await deleteDoc(doc(scheduleRef.value, id))
  }
  async function setPerformer (item: EventPerformer) {
    await setDoc(doc(performersRef.value, item.id), item)
  }
  async function removePerformer (id: string) {
    await deleteDoc(doc(performersRef.value, id))
  }
  function generateId() {
    return doc(scheduleRef.value).id // Does not need to be specific collection
  }

  return {
    currentMember,
    event,
    eventId,
    eventRef,
    generateId,
    members,
    performers,
    removePerformer,
    removeScheduleItem,
    setEvent,
    setPerformer,
    setScheduleItem,
    schedule,
  }
})
