<template>
  <ion-page>
    <ion-header collapse="fade" translucent>
      <ion-toolbar>
        <ion-title>Účinkující</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen>
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Účinkující</ion-title>
        </ion-toolbar>
      </ion-header>

      <PerformerCard
        v-for="item in $store.performers"
        :key="item.id"
        :item="item"
        :opened="openedPerformerCard === item.id"
        @headerClicked="toggleCard(item.id)"
      />

      <ion-button v-if="$store.currentMember?.canWrite" expand="full" fill="clear" class="ion-margin-top" @click="createPerformer">
        <ion-icon slot="icon-only" :icon="addCircleOutline" />
      </ion-button>
    </ion-content>
  </ion-page>
</template>

<script lang="ts" setup>
import { IonButton, IonPage, IonHeader, IonIcon, IonToolbar, IonTitle, IonContent, modalController } from '@ionic/vue'
import PerformerCard from '@/components/PerformerCard.vue'
import { addCircleOutline } from 'ionicons/icons'

import { ref } from 'vue'
import { useEventStore } from '@/store/event'

const $store = useEventStore()

const openedPerformerCard = ref('')
function toggleCard(id = '') {
  openedPerformerCard.value = openedPerformerCard.value === id ? '' : id
}

async function createPerformer () {
  const PerformerForm = await import('@/components/PerformerForm.vue')
  return (await modalController.create({
    component: PerformerForm.default,
    ...PerformerForm.modalOptions
  })).present()
}
</script>
