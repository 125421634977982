import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import { getCurrentUser } from 'vuefire'

import EventTabs from '@/views/EventTabs.vue'
import EventScheduleTab from '@/views/EventScheduleTab.vue'
import EventPerformersTab from '@/views/EventPerformersTab.vue'
import EventInfoTab from '@/views/EventInfoTab.vue'
import ListEvents from '@/views/ListEvents.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/event'
  },
  {
    path: '/events',
    component: ListEvents
  },
  {
    path: '/event',
    component: EventTabs,
    beforeEnter: () => {
      if (!localStorage.getItem('currentEventId')) {
        return '/events'
      }
    },
    children: [
      {
        path: '',
        redirect: '/event/schedule'
      },
      {
        path: 'schedule',
        component: EventScheduleTab
      },
      {
        path: 'performing',
        component: EventPerformersTab
      },
      {
        path: 'info',
        component: EventInfoTab
      }
    ]
  },
  {
    path: '/auth',
    component: () => import('@/views/AuthPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to) => {
  // Routes with `meta: { requiresAuth: true }` will require authentication
  if (to.meta.requiresAuth) {
    const currentUser = await getCurrentUser()
    // If the user is not logged in, redirect to the login page
    if (!currentUser) {
      return {
        path: '/auth',
        query: { redirect: to.fullPath },
      }
    }
  }
})

export default router
