import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useIonRouter } from '@ionic/vue'
import { getCurrentUser, useFirebaseAuth } from 'vuefire'
import { sendSignInLinkToEmail, signInWithEmailLink, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import type { User } from 'firebase/auth'


export const useAuthStore = defineStore('auth', () => {
  const auth = useFirebaseAuth()!
  const $router = useIonRouter()

  const currentUser = ref<User>()

  async function fetchUserInfo () {
    currentUser.value = await getCurrentUser() ?? undefined
  }

  fetchUserInfo()

  async function loginWithEmail (email: string, baseUrl: string) {
    await sendSignInLinkToEmail(auth, email, {
      url: baseUrl + '/auth',
      handleCodeInApp: true,
    })
  }

  async function verifyEmailLink (email: string, link: string) {
    await signInWithEmailLink(auth, email, link)
    await fetchUserInfo()
  }

  async function loginWithPassword (email: string, password: string) {
    await signInWithEmailAndPassword(auth, email, password)
    await fetchUserInfo()
  }

  async function logout () {
    await signOut(auth)
    await fetchUserInfo()
    $router.navigate('/auth', 'back', 'push')
  }

  return {
    currentUser,
    fetchUserInfo,
    loginWithEmail,
    loginWithPassword,
    logout,
    verifyEmailLink,
  }
})
