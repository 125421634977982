<template>
  <ion-card :class="{ opened: props.opened }">
    <ion-card-header :style="{ '--photoUrl': `url(${item.photoUrl})` }" @click="$emit('headerClicked')">
      <ion-card-title>{{ item.name }}</ion-card-title>
    </ion-card-header>
    <div class="content" ref="contentDiv">
      <ion-card-content @VnodeMounted="observeContentSize">
        <div v-if="isAdmin" class="item-actions">
          <ion-button fill="clear" size="small" @click="editItem">
            <ion-icon slot="icon-only" :icon="createOutline" />
          </ion-button>
        </div>
        <ion-card-title class="ion-padding-bottom">
          {{ item.name }}
        </ion-card-title>
        <TextFormatter :value="item.description" />
        <ContentBlocks :value="item.blocks" />
      </ion-card-content>
    </div>
  </ion-card>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon, modalController } from '@ionic/vue'
import { createOutline } from 'ionicons/icons'
import ContentBlocks from '@/components/ContentBlocks.vue'
import TextFormatter from '@/components/TextFormatter.vue'

import { useEventStore } from '@/store/event'
import type { EventPerformer } from '@/store/event'

const props = defineProps<{
  item: EventPerformer
  opened: boolean
}>()

const contentDiv = ref<HTMLDivElement>()
function observeContentSize ({ el }: any) {
  (new ResizeObserver(([{ target }]) => {
    if (contentDiv.value)
      contentDiv.value.style.setProperty('--offsetHeight', (target as HTMLElement).offsetHeight + 'px')
  })).observe(el)
}

const isAdmin = useEventStore().currentMember?.canWrite

async function editItem () {
  const PerformerForm = await import('@/components/PerformerForm.vue')
  return (await modalController.create({
    component: PerformerForm.default,
    componentProps: { origItem: props.item },
    ...PerformerForm.modalOptions
  })).present()
}

</script>

<style scoped>
ion-card-header {
  background-size: cover;
  background-image: linear-gradient(transparent, #0005), var(--photoUrl);
  aspect-ratio: 3 / 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
}

ion-card-header ion-card-title {
  --color: white;
  text-shadow: 0 0 2px black;
  transition: transform 0.3s;
  transform: translateY(0);
}

.opened ion-card-header ion-card-title {
  transform: translateY(200%);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s;
}

.opened .content {
  max-height: var(--offsetHeight, 1000px);
}

.item-actions {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 5px;
  font-size: 24px;
}
.item-actions ion-button {
  --padding-end: 0.45em;
  --padding-start: 0.45em;
}
</style>
