<template>
  <ion-page>
    <ion-header collapse="fade" translucent>
      <ion-toolbar>
        <ion-title>Info</ion-title>
        <ion-buttons v-if="$store.currentMember?.canWrite" slot="end">
          <ion-button fill="clear" size="small" @click="editPage">
            <ion-icon slot="icon-only" :icon="createOutline" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen>
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Info</ion-title>
        </ion-toolbar>
      </ion-header>

      <div v-html="$store.event?.infoTab?.content" />

      <ion-card v-if="false">
        <!-- Make sure components are compiled for v-html -->
        <ion-card-header><ion-card-title /><ion-card-subtitle /></ion-card-header>
        <ion-list><ion-item><ion-avatar /><ion-label /><ion-note /></ion-item></ion-list>
      </ion-card>

      <div class="app_version_tilde" @click="alertAppVersion">~</div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts" setup>
import { IonAvatar, IonButton, IonButtons, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon, IonItem, IonLabel, IonList, IonContent, IonHeader, IonNote, IonPage, IonTitle, IonToolbar, modalController } from '@ionic/vue'
import { createOutline } from 'ionicons/icons'

import { CapacitorUpdater } from '@capgo/capacitor-updater'
import { useEventStore } from '@/store/event'

const $store = useEventStore()

function alertAppVersion () {
  CapacitorUpdater.current().then(cap => alert(`Verze aplikace: ${process.env.VUE_APP_BUILD_DATETIME} / bundle: ${cap.bundle.version}, ${cap.bundle.status} / native: ${cap.native}`))
}

async function editPage () {
  const InfoTabForm = await import('@/components/InfoTabForm.vue')
  return (await modalController.create({
    component: InfoTabForm.default,
    ...InfoTabForm.modalOptions
  })).present()
}
</script>

<style scoped>
:deep(.num-list__number) {
  font-size: 30px;
  font-weight: 600;
  width: 22px;
  white-space: nowrap;
}

.app_version_tilde {
  margin: 10px;
  font-size: 30px;
  text-align: center;
  color: #ccc;
}
</style>
