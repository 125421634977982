<template>
  <ion-page>
    <ion-content>
      <ion-header class="event-banner__header" mode="ios" :style="{ backgroundImage: `url(${$store.event?.bannerUrl})` }">
        <ion-buttons class="event-banner__actions">
          <ion-button :id="`trigger-${$.uid}`" :style="{ color: 'currentColor' }">
            <ion-icon slot="icon-only" :icon="ellipsisVertical" />
          </ion-button>
          <ion-popover :trigger="`trigger-${$.uid}`" trigger-action="click" dismiss-on-select>
            <ion-list lines="full" class="ion-no-padding">
              <ion-item router-link="/events" router-direction="back" :detail="false">
                Přepnout událost
              </ion-item>
            </ion-list>
          </ion-popover>
        </ion-buttons>
        <ion-toolbar>
          <ion-title size="large" class="event-banner__title">
            {{ $store.event?.displayName }}
          </ion-title>
        </ion-toolbar>
      </ion-header>

      <NotificationsBanner v-if="$store.event" :event-id="$store.event.id" />

      <template v-for="[day, items] in days" :key="day">
        <ion-list-header>
          <ion-label>{{ format(new Date(day), 'EEEE') }}</ion-label>
          <ion-button v-if="$store.currentMember?.canWrite" size="small" fill="clear" @click="createScheduleItem(items[0].time.start)">
            <ion-icon slot="icon-only" :icon="addCircleOutline" />
          </ion-button>
        </ion-list-header>

        <ion-accordion-group v-model="openedScheduleItem">
          <ScheduleItem
            v-for="item in items"
            :key="item.id"
            :value="item.id"
            :item="item"
            :is-current="ongoingItem?.id === item.id"
          />
        </ion-accordion-group>
      </template>

      <div class="ion-text-right" v-if="$store.currentMember?.canWrite">
        <ion-button  size="small" fill="clear" @click="createScheduleItem()">
          <ion-icon slot="icon-only" :icon="addCircleOutline" />
        </ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts" setup>
import { IonAccordionGroup, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonPopover, IonTitle, IonToolbar, modalController, onIonViewDidEnter } from '@ionic/vue'
import { addCircleOutline, ellipsisVertical } from 'ionicons/icons'
import NotificationsBanner from '@/components/NotificationsBanner.vue'
import ScheduleItem from '@/components/ScheduleItem.vue'

import { computed, onMounted, ref } from 'vue'
import { useEventStore, EventScheduleItem } from '@/store/event'
import { format } from '@/lib/date-fns-local'
import { useTimestamp } from '@vueuse/core'
import { App } from '@capacitor/app'

const openedScheduleItem = ref()
const $store = useEventStore()

const days = computed<Map<string, EventScheduleItem[]>>(() => {
  const days = new Map()
  $store.schedule.slice()
    .sort((a, b) => a.time.start.valueOf() - b.time.start.valueOf())
    .forEach(item => {
      const day = item.time.start.toDateString()
      const collection = days.get(day)
      if (collection) {
        collection.push(item)
      } else {
        days.set(day, [item])
      }
    })

  return days
})

const currentTime = useTimestamp({ interval: 60000 })
const ongoingItem = computed(() => {
  const now = new Date(currentTime.value)
  const day = days.value.get(now.toDateString())
  if (!day) return undefined
  
  for (let i = day.length - 1; i >= 0; i--) {
    if (day[i].time.start <= now) {
      return day[i]
    }
  }
  return day[0]
})

function scrollToOngoingItem () {
  if (!ongoingItem.value) return
  const cursor = document.getElementById('ongoing-cursor')
  if (!cursor) return
  cursor.scrollIntoView({ behavior: 'smooth', block: 'center' })
}

onMounted(scrollToOngoingItem)
onIonViewDidEnter(scrollToOngoingItem)
App.addListener('appStateChange', async ({ isActive }) => {
  if (isActive) scrollToOngoingItem()
})


async function createScheduleItem (defaultStartTime?: Date) {
  if (!defaultStartTime && days.value.size) {
    const lastDay = Array.from(days.value.values()).pop()
    defaultStartTime = lastDay?.pop()?.time.start
  }
  const ScheduleItemForm = await import('@/components/ScheduleItemForm.vue')
  return (await modalController.create({
    component: ScheduleItemForm.default,
    componentProps: { defaultStartTime },
    ...ScheduleItemForm.modalOptions
  })).present()
}
</script>

<style scoped>
.event-banner__header {
  background-position: 50% 50%;
  background-size: cover;
  padding-top: 190px;
}

.event-banner__header * {
  --background: none !important;
}

.event-banner__title {
	color: #fff;
	letter-spacing: normal;
	text-shadow: 1px 1px 6px #0008;
}

.event-banner__actions {
  position: absolute;
  top: calc(8px + env(safe-area-inset-top));
  right: env(safe-area-inset-right);
}

.event-banner__actions {
  color: #fff;
}
</style>
